/*------------------------------------*\
    #HEADER
\*------------------------------------*/

// positionnement
@media (min-width: $screen-sm-min) {
  .header__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.header__box {
  margin-top: $spacing-sm*1.2;
  margin-bottom: $spacing-md*2;
  h1 {
    @media (max-width: $screen-xs-max) {
      text-align: center;
    }
    span {
      display: block;
      @extend %font-size-md;
    }
    text-align: center;
  }
}

.header__nav {
  @media (max-width: $screen-xs-max) {
    margin-top: $spacing-sm;
  }
}

.header__nav .nav-list{
  display: flex;
  align-items: baseline;
  @media (max-width: $screen-xs-max) {
    justify-content: center;
  }
  .nav__item:first-of-type {
    margin-right: $spacing-sm;
  }
  .widget,
  .cart-mobile {
    margin-left: $spacing-xl;
  }
}

body:not(.touch) .header__nav .cart-mobile {
  display: none;
}

body.touch .header__nav .widget_shopping_cart {
  display: none !important;
}
