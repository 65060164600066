/*------------------------------------*\
    #BILLING-FIELDS
\*------------------------------------*/


.woocommerce-billing-fields,
.woocommerce-shipping-fields {
  h3 {
    @extend %font-var;
    @extend %font-size-lg;
    margin-bottom: $spacing-md;
  }
}
.woocommerce-billing-fields input,
.woocommerce-shipping-fields input {
  background-color: rgba($base-color, .2);
  border: none;
  padding: $spacing-xs;
  color: $base-color;
  @extend %font-body;
  @extend %font-size-md;
  &:focus {
    background-color: rgba($base-color, .3);
  }
}

.woocommerce form .form-row-first,
.woocommerce form .form-row-last,
.woocommerce-page form .form-row-first,
.woocommerce-page form .form-row-last {
  width: calc(50% - 5px);
  @media (max-width: $screen-xs-max) {
    width: 100%;
  }
}

.woocommerce form .form-row.woocommerce-invalid .select2-container,
.woocommerce form .form-row.woocommerce-invalid input.input-text,
.woocommerce form .form-row.woocommerce-invalid select {
  border-color: red;
}

.woocommerce form .form-row.woocommerce-invalid label {
  color: red;
}

.select2-container .select2-choice {
  border-radius: 0;
  background-color: rgba($base-color, .2);
  color: $base-color;
  border: none;
}

.select2-dropdown-open {
  background-color: $base-color;
  a span {
    color: $body-color;
  }
}

.select2-results {
  background-color: $base-color;
  color: $body-color;
  @extend %font-body;
  @extend %font-size-md;
}

.select2-drop-active {
  border: none;
}

.select2-results .select2-highlighted {
  border-radius: 0;
  background-color: $body-color;
  color: $base-color;
}

.woocommerce-shipping-fields {
  margin-top: $spacing-lg;
}

.woocommerce-shipping-fields h3 label {
  @extend %font-size-md;
  border-bottom: $border-width-sm solid $action-response;
  padding-bottom: $spacing-xxs;
}

.form-row.notes {
  margin-bottom: $spacing-lg !important;
  padding: 0 !important;
  textarea {
    @extend %font-body;
    @extend %font-size-md;
    color: $base-color;
    line-height: 1 !important;
    padding: $spacing-xs;
    border: none;
    background-color: rgba($base-color, .2);
    &:focus {
      background-color: rgba($base-color, .3);
    }
  }
}