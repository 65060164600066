/*------------------------------------*\
    #ORDER-REVIEW
\*------------------------------------*/

h3#order_review_heading {
  @extend %font-var;
  @extend %font-size-lg;
  margin: $spacing-md 0;
}

.woocommerce-checkout-review-order-table,
.order_details {
  tfoot,
  thead {
    th,
    td {
      background-color: rgba($action-response, .3);
      border-bottom: $border-width-sm dashed $base-color;
      font-weight: normal !important;
    }
    td {
      text-align: right;
    }
  }
}

.woocommerce-checkout-review-order-table thead  {
  th:last-of-type {
    text-align: right;
  }
}

.woocommerce-checkout-review-order-table tbody {
  td:last-of-type {
    text-align: right;
  }
}

.woocommerce-checkout-review-order-table .woocommerce-Price-amount {
  display: block;
  @extend %font-body;
  @extend %font-size-md;
}