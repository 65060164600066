/*------------------------------------*\
    #CART-TOTALS
\*------------------------------------*/


.cart_totals h2 {
  @extend %font-body;
  @extend %font-size-sm;
  border-top: $border-width-sm solid $base-color;
  padding-top: $spacing-xs;
  margin-bottom: $spacing-md;
}

.cart_totals .shop_table {
  th {
    border-bottom: none;
    padding-left: 0 !important;
  }
  td,
  th {
    border-top-style: dashed !important;
  }
  td {
    @extend %font-body;
    @extend %font-size-md;
  }
  td:before {
    display: block !important;
    float: none !important;
    text-align: left !important;
  }
}

.woocommerce-shipping-calculator {
  margin: $spacing-xxs 0 $spacing-xs 0;
  select,
  input {
    @extend %font-body;
    @extend %font-size-md;
  }
  button.button {
    margin-top: $spacing-sm;
    border-radius: 0;
    background-color: $base-color;
    color: $body-color;
    @extend %font-size-sm;
    @extend %font-body;
  }
}

span.amount {
  @extend %font-body;
  @extend %font-size-md;
  font-weight: normal !important;
}

a.shipping-calculator-button {
  @extend %font-body;
  @extend %font-size-sm;
  display: inline-block;
  border-bottom: $border-width-sm $action-response solid;
}

section.shipping-calculator-form {
  margin-top: $spacing-sm;
}