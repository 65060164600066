/*------------------------------------*\
    #CHECKOUT-PAYMENT
\*------------------------------------*/


.woocommerce-checkout-payment#payment {
  background-color: $base-color;
  color: $body-color;
  border-radius: 0;
  label {
    @extend %font-body;
    @extend %font-size-md;
  }
}

.woocommerce-checkout #payment div.payment_box {
  background-color: $body-color;
  color: $base-color;
  &:before {
    border-bottom-color: $body-color;
  }
}

ul.payment_methods {
  border-bottom: $body-color dashed $border-width-sm !important;
}

input#place_order {
  @extend %button-special;
}