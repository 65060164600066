/*------------------------------------*\
    #DETAIL-IMAGEs
\*------------------------------------*/

.product-detail .images,
.product-detail .images .thumbnail {
  a {
    display: block;
    width: 100% !important;
    cursor: default;
    pointer-events: none !important;
  }
}