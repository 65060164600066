/*------------------------------------*\
  #DEFINITIONS
\*------------------------------------*/


dl {
  @extend %font-size-md;
  dt {
    padding-bottom: .3em;
    &:first-of-type {
      padding-top: .6em;
      border-top: $border-width-sm solid $base-color;
    }
  }
  dd{
    padding-bottom: .6em;
    margin-bottom: .6em;
    border-bottom: $border-width-sm $base-color solid;
  }
}



/*------------------------------------*\
  #ORDERED
\*------------------------------------*/


.content ol {
  counter-reset:li;
  li {
    position: relative;
    padding-left: 1.2em;
    @extend %font-size-md;
    margin-bottom: .5em;
    &:before {
      content:counter(li);
      counter-increment:li;
      position: absolute;
      left: 0;
      font-size: 1em;
      color: rgba($base-color, .5);
    }
  }
  ol > li:first-of-type {
    margin-top: .5em;
  }
}


/*------------------------------------*\
  #UNORDERED
\*------------------------------------*/


.content ul {
  li {
    position: relative;
    padding-left: 1.2em;
    @extend %font-size-md;
    margin-bottom: .5em;
    &:before {
      content: "-";
      position: absolute;
      left: 0;
      font-size: 1em;
    }
  }
  ol > li:first-of-type {
    margin-top: .5em;
  }
}

ul.nav-list {
  list-style: none;
}



/*------------------------------------*\
  #TABLE
\*------------------------------------*/


.content table {
  width: 100%;
  thead {
    border-bottom: $border-width-md solid $base-color;
  }
  th {
    text-align: left;
  }
  tbody tr {
    &:nth-child(odd) {
      background-color: rgba($base-color, .1);
    }
  }
  tfoot {
    border-top: $border-width-md solid $base-color;
  }
  th,
  td {
    padding: .5em 0;
  }
  @media (max-width: $screen-xs-max) {
    th,
    td {
      display: block;
    }
  }
}


/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/

.woocommerce a.button {
  border-radius: 0;
  background-color: $body-color;
  color: $base-color;
  border: $border-width-sm solid $base-color;
  @extend %font-body;
  @extend %font-size-md;
  &:hover {
    background-color: $body-color;
    color: $action-response;
    border-color: $action-response;
  }
}

%button-special {
  color: $body-color;
  background-color: $action-response;
  @extend %font-body;
  @extend %font-size-md;
  border-radius: 0;
  border: $border-width-sm solid $body-color;
  &:hover {
    color: $body-color;
    background-color: rgba($action-response, .8);
  }
}
