/*------------------------------------*\
    #DETAIL-DESCRIPTION
\*------------------------------------*/

.product-detail .summary:after {
  content: "";
  display: table;
  clear: both;
}

.product-detail h1 {
  @extend %font-size-lg;
  margin-bottom: $spacing-xs;
}

.woocommerce div.product p.price {
  @extend %font-size-md;
  margin-bottom: $spacing-lg;
}

.product-detail div[itemprop=description] {
  margin-bottom: $spacing-md;
}


.product-detail div[itemprop=description] table {
  margin-top: $spacing-lg;
  margin-bottom: $spacing-lg;
  width: 100%;
  tr {
    border-bottom: $border-width-sm solid $base-color;
  }
  td {
    @extend %font-size-sm;
    padding-top: $spacing-xs;
    padding-bottom: $spacing-xs;
  }
}