/*------------------------------------*\
    #RELEASE
\*------------------------------------*/


.release {
  position: relative;
  border-top: $border-width-sm solid $base-color;
  padding-top: $spacing-xs;
  padding-bottom: $spacing-xxl;
}

.release__title {
  @extend %font-body;
  @extend %font-size-sm;
  margin-bottom: $spacing-lg;
}

.release__list {
  outline: none;
  &:not(.flickity-enabled) {
    display: none;
  }
}

.flickity-viewport {
  overflow: hidden;
}

.release__item {
  width: 100%;
  display: flex;
  @media (max-width: $screen-xs-max) {
    flex-direction: column;
  }
}

@media (min-width: $screen-sm-min) {
  .release__media {
    width: 33%;
    padding-right: calc(1.5rem + 10px);
  }
  .release__content {
    flex: 1;
    padding-left: calc(1.5rem - 5px);
  }
}

@media (max-width: $screen-xs-max) {
  .release__media,
  .release__content {
    width: 100%;
  }
}

.release__media {
  @media (max-width: $screen-xs-max) {
    margin-bottom: $spacing-md;
  }
  img {
    width: 100%;
  }
}

.release__content {
  h3 {
    @extend %font-size-lg;
    margin-bottom: $spacing-md;
  }
  .content {
    margin-bottom: $spacing-sm; 
  }
  .price {
    color: $action-response;
    @extend %font-size-sm;
    display: block;
    margin-bottom: $spacing-md;
  }
  .woocommerce & a.button {
    @extend %font-var;
    @extend %font-size-sm;
    padding: $spacing-xxs;
  }
}

.flickity-page-dots {
  position: absolute;
  top: $spacing-xxs;
  right: 0;
  .dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    cursor: pointer;
    background: url("../assets/images/bullet.svg") no-repeat center center;
    &:not(:last-of-type) {
      margin-right: $spacing-xxs/2;
    }
    &.is-selected {
      background: url("../assets/images/bullet-active.svg") no-repeat center center;
    }
  }
}