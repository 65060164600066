/*------------------------------------*\
    #WIDGET-SEARCH
\*------------------------------------*/

.widget_product_search {
  h2,
  label {
    display: none;
  }
}

.woocommerce-product-search {
  display: flex;
  align-items: center;
  input[type="search"],
  input[type="submit"], {
    -webkit-appearance: none;
    height: $form-height;
    border: $border-width-sm $base-color solid;
    background: $body-color;
    color: $base-color;
    outline: none;
    padding: 0 $spacing-xs;
    @extend %font-size-sm;
    @extend %font-body;
    &:focus {
      outline: none;
    }
  }
  input[type="search"] {
    border-right-color: $body-color;
  }
  input[type="submit"] {
    border-left-color: $body-color;
    &:hover {
      color: $action-response;
    }
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px $action-response inset !important;
}