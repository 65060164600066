/*------------------------------------*\
    #MESSAGE
\*------------------------------------*/


.woocommerce-message,
.woocommerce-error,
.woocommerce-info {
  border: none;
  background: $action-response;
  color: $body-color;
  padding: $spacing-sm !important;
  @media (max-width: $screen-xs-max) {
     padding: $spacing-xs !important;
  }
  &:before {
    content: none;
  }
  a:not(.button):hover {
    color: $body-color;
  }
}

.woocommerce-message a.button,
.woocommerce-error a.button,
.woocommerce-info a.button {
  color: $body-color;
  @extend %font-var;
  border-radius: 0;
  padding: 0 !important;
  border: none;
  border-bottom: $border-width-sm solid $body-color;
  background-color: transparent;
}

.woocommerce-error {
  background: red;
  li {
    width: 100%;
  }
}