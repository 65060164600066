/*------------------------------------*\
    #INDEX
\*------------------------------------*/


.index {
  border-top: $border-width-sm solid $base-color;
}

.index__title {
  @extend %font-size-sm;
  @extend %font-body;
}

.woocommerce-result-count,
.woocommerce-ordering {
  display: none;
}

.woocommerce ul.products {
  @media (max-width: $screen-xs-max) {
    margin-left: -$spacing-xs;
    margin-right: -$spacing-xs;
  }
  @media (min-width: $screen-sm-min) {
    margin-left: -$spacing-md;
    margin-right: -$spacing-md;
  }
  
}

.woocommerce ul.products li.product {
  display: inline-block;
  vertical-align: top;
  float: none;
  margin: 0;
  @media (max-width: $screen-xs-max) {
    width: calc(50% - 6px);
    padding: $spacing-xs;
    margin-bottom: $spacing-lg;
    &:nth-child(3n+1) {
      width: 100%;
    }
  }
  @media (min-width: $screen-sm-min) {
    width: calc(25% - 6px);
    padding: $spacing-md;
    &:nth-child(7n+1),
    &:nth-child(7n+2),
    &:nth-child(7n+3), {
      width: calc(33% - 2px);
    }
  }
}

body.search section.release {
  display: none;
}