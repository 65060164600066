/*------------------------------------*\
    #CORE TYPOGRAPHY
\*------------------------------------*/


h1,
h2 {
  @extend %font-var;
  @extend %font-size-lg;
}

h3,
h4,
h5,
h6 {
  @extend %font-size-lg;
}

p {
  @extend %font-size-md;
}

blockquote p {
  @extend %font-size-md;
  text-align: center;
}

a {
  text-decoration: none;
  color: $action;
  &:hover {
    color: $action-response;
  }
}

em,
i{
  @extend %font-var;
}

b,
strong{
  @extend %font-var;
}

em > b,
b > em,
i > b,
b > i,
em > strong,
strong > em,
i > strong,
strong > i {
  @extend %font-var;
}

em > b,
b > em,
i > b,
b > i,
em > strong,
strong > em,
i > strong,
strong > i {
  @extend %font-var;
}
p + h2,
ul + h2,
ol + h2,
blockquote + h2,
p + h2,
ul + h2,
ol + h2,
blockquote + h2,
p + h3,
ul + h3,
ol + h3,
blockquote + h3,
p + h4,
ul + h4,
ol + h4,
blockquote + h4 {
  margin-top: 2em;
}
.body {
  h2 {
    @extend %font-size-lg;
  }
  h3 {
    @extend %font-size-md;
    @extend %font-var;
  }
  h4,
  h5,
  h6 {
    @extend %font-size-md;
  }
}
.body h2,
.body h3,
.body h4,
.body blockquote {
  margin-bottom: 1em;
}
.body h2 + h3,
.body h3 + h4 {
  margin-top: 0;
}

.body ul,
.body ol,
.body .embed-container {
  margin-bottom: $spacing-md;
  @extend %font-size-md;
}

.body p {
  margin-bottom: .5em;
}

.body a {
  color: $action-response;
}

.body {
  max-width: 660px;
}

.page-template-default:not(.woocommerce-page) .body {
  max-width: 600px;
}

p sub, p sup {
  font-size: .75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

p sup {
  top: -0.5em;
}

p sub {
  bottom: -0.25em;
}

p small{
  @extend %font-size-sm;
}

p abbr {
  border-bottom: $border-width-sm dotted $base-color;
  cursor: help;
}

p q,
p cite {
 &:before {
    content:'"';
 }
 &:after {
    content:'"';
 }
}

p mark {
  background-color: rgba($action, .6);
  color: $base-color;
}

p code,
p samp,
pre {
  font-family: monospace;
  @extend %font-size-md;
}

hr {
  background: $base-color;
  height: $border-width-md;
  border: none;
  margin: 0;
}

figcaption {
  @extend %font-size-sm;
  margin-top: $spacing-xs;
}

ol, ul, pre, dl {
  @extend %font-size-md;
}

ul ul,
ol ol {
  @extend %font-size-md;
}

.text ul {
  margin-left: 1em;
}

.text ol {
  margin-left: 1em;
}

.text > ul,
.text > ol {
  margin-bottom: 1em;
}

li {
  line-height: inherit;
}
