/*------------------------------------*\
    #INFO-FORM
\*------------------------------------*/

.social__newsletter {
  margin: $spacing-xl 0;
  h4 {
    @extend %font-size-md;
    @extend %font-var;
    margin-bottom: 0;
  }
}

.info__form {
  #mc_embed_signup label {
    display: none;
  }
  #mc_embed_signup_scroll {
    display: flex;
    @media (max-width: $screen-xs-max) {
      flex-direction: column;
    }
  }
  #mc_embed_signup input.email {
    border: $border-width-sm solid $base-color;
    border-radius: 0;
    width: 100%;
    padding: 0 $spacing-xs;
    @extend %font-size-md;
    @extend %font-body;
    outline: none;
    appearance: none;
  }
  #mc_embed_signup .button {
    border: $border-width-sm solid $base-color;
    background-color: $body-color;
    @extend %font-size-md;
    @extend %font-body;
    border-radius: 0;
    margin-left: -1px;
    color: $base-color;
    transition: all .3s ease-in-out;
    appearance: none;
    &:hover {
      background-color: $base-color;
      color: $body-color;
    }
  }
  #mc_embed_signup input.email,
  #mc_embed_signup .button {
    height: 46px;
  }
}