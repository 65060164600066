/*------------------------------------*\
    #TRACKLIST
\*------------------------------------*/


.tracklist {
  margin-bottom: $spacing-lg;
  width: 100%;
}

.tracklist__row {
  border-bottom: $border-width-sm solid $base-color;
  &.has-sound {
    cursor: pointer;  
    &:hover {
      color: $action-response;
    }
  }
  td {
    @extend %font-size-sm;
    padding-top: $spacing-xs;
    padding-bottom: $spacing-xs;
  }
}

.tracklist__number,
.tracklist__time {
  @extend %font-var;
}

.tracklist__number {
  padding-right: $spacing-sm;
}

.tracklist__time {
  padding: 0 $spacing-sm;
}

.playpause {
  display: block;
  cursor: pointer;
  width: 16px;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 26px;
    background: url('../assets/images/play.svg') no-repeat center center;
  }
  .playing &:after {
    animation: rotating 3s linear infinite;
    background: url('../assets/images/pause.svg') no-repeat center center;
  }
}

@keyframes rotating {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

.tracklist__row:hover .playpause:after {
  background: url('../assets/images/play-active.svg') no-repeat center center;
}
.tracklist__row.playing:hover .playpause:after {
  background: url('../assets/images/pause-active.svg') no-repeat center center;
}