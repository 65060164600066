/*------------------------------------*\
    #CART
\*------------------------------------*/


body.woocommerce-cart,
body.woocommerce-checkout {
  .site-content-contain {
    @extend .container;
  }
  .site-content {
    @extend .row;
    & > .wrap {
      @extend .col-sm-12;
    }
  }
  .content-area {
    border-top: $border-width-sm solid $base-color;
  }
  .entry-title {
    @extend %font-body;
    @extend %font-size-sm;
    margin-top: $spacing-xs;
    margin-bottom: $spacing-xl;
  }
}

.woocommerce-cart .wc-proceed-to-checkout a.checkout-button {
  @extend %button-special;
}

form.woocommerce-checkout {
  .col2-set {
    .col-1,
    .col-2 {
      float: none;
      width: 100%;
    }
  }
}