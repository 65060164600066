/*------------------------------------*\
    #CART-TABLE
\*------------------------------------*/


.woocommerce table.shop_table {
  margin-bottom: $spacing-lg;
}

.shop_table td {
  @extend %font-body;
  @extend %font-size-md;
  &:before {
    font-weight: normal !important;
  }
  @media (max-width: $screen-sm-max) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.shop_table th {
  @extend %font-size-md;
  @extend %font-var;
  font-weight: normal !important;
  border-bottom: $border-width-sm solid $base-color;
}

.shop_table tr.cart_item {
  @media (max-width: $screen-sm-max) {
    margin-bottom: $spacing-lg !important;
    td {
      text-align: left !important;
      a,
      span.amount,
      input {
        width: 100%;
        text-align: left !important;
        display: block;
        margin-bottom: $spacing-xxs;
      }
    }
    td:before {
      display: block;
      float: none !important;
      text-align: left !important;
      margin-bottom: $spacing-xxs;
    }
    .product-remove {
      &:before {
        display: none !important;
      }
      a {
        width: 100%;
        margin-bottom: $spacing-sm;
      }
    }
  }
}

.shop_table tr.cart_item td {
  border-bottom: $border-width-sm solid $base-color;
  @extend %font-size-md;
  @extend %font-body;
  &:before {
    @extend %font-size-md;
    @extend %font-var;
    font-weight: normal !important;
  }
}

.shop_table a.remove {
  font-size: 0;
  display: block;
  width: 30px;
  height: 30px;
  background: url('../assets/images/cross-white.svg') no-repeat center center;
  &:hover {
    background: url('../assets/images/cross-red.svg') no-repeat center center;
  }
}

.shop_table input.button {
  border-radius: 0;
  background-color: $base-color;
  color: $body-color;
  @extend %font-size-sm;
  @extend %font-body;
  &:disabled[disabled] {
    opacity: .3;
    color: $body-color;
    &:hover {
      color: $body-color;
    }
  }
}

.shop_table td.actions {
  padding-top: $spacing-md !important;
}