/*------------------------------------*\
    #VARIATIONS
\*------------------------------------*/

.variations {
  margin: $spacing-md 0;
  td {
    display: block;
  }
  .label {
    @extend %font-var;
    @extend %font-size-md;
    margin-bottom: $spacing-xs;
  }
  .reset_variations {
    @extend %font-var;
    @extend %font-size-sm;
    margin-left: $spacing-xs;
    text-decoration: underline;
  }
}