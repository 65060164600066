/*------------------------------------*\
    #CHECKOUT
\*------------------------------------*/


.woocommerce-billing-fields,
.woocommerce-shipping-fields {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.woocommerce-checkout-payment {
  a {
    color: $action-response;
  }
}