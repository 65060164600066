/*------------------------------------*\
    #CONTAINER
\*------------------------------------*/

.container {
  width: 96%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

body.page,
body.single, {
  padding-bottom: $spacing-xl*2;
}