/*------------------------------------*\
  #COLORS
\*------------------------------------*/


// Main colour palette
$black            : #201818;
$white            : #FFFFFF;
$grey             : #828282;
$green            : #10A90F;
$red              : #FF0000;


// Assign colours to variable roles
$body-color       : $black;
$base-color       : $white;
$action           : $base-color;
$action-response  : $green;



/*------------------------------------*\
  #BREAKPOINTS
\*------------------------------------*/

// Extra small screen / phone
$screen-xs:                  400px !default;
$screen-xs-min:              $screen-xs !default;

// Small screen / tablet
$screen-sm:                  700px !default;
$screen-sm-min:              $screen-sm !default;

// Medium screen / desktop
$screen-md:                  900px !default;
$screen-md-min:              $screen-md !default;

// Large screen / wide desktop
$screen-lg:                  1400px !default;
$screen-lg-min:              $screen-lg !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

// Number of columns in the grid.
$grid-columns:              12 !default;
//Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         20px !default;


/*------------------------------------*\
  #BORDERS WIDTH
\*------------------------------------*/


$border-width-sm         : 1px;
$border-width-md         : $border-width-sm*2;



/*------------------------------------*\
  #FONTS
\*------------------------------------*/

@font-face {
  font-family: 'terminal_grotesque_openRg';
  src: url('../assets/fonts/terminal-grotesque_open-webfont.eot');
  src: url('../assets/fonts/terminal-grotesque_open-webfont.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/terminal-grotesque_open-webfont.woff2') format('woff2'),
       url('../assets/fonts/terminal-grotesque_open-webfont.woff') format('woff'),
       url('../assets/fonts/terminal-grotesque_open-webfont.ttf') format('truetype'),
       url('../assets/fonts/terminal-grotesque_open-webfont.svg#terminal_grotesque_openRg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'terminal_grotesque';
    src: url('../assets/fonts/terminal-grotesque-webfont.eot');
    src: url('../assets/fonts/terminal-grotesque-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/terminal-grotesque-webfont.woff2') format('woff2'),
         url('../assets/fonts/terminal-grotesque-webfont.woff') format('woff'),
         url('../assets/fonts/terminal-grotesque-webfont.ttf') format('truetype'),
         url('../assets/fonts/terminal-grotesque-webfont.svg#terminal_grotesqueregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

%font-body {
  font-family: 'terminal_grotesque', sans-serif;
  font-weight: normal;
  font-style: normal;
}

%font-var {
  font-family: 'terminal_grotesque_openRg', sans-serif;
  font-weight: normal;
  font-style: normal;
}



/*------------------------------------*\
    #TYPESIZE/SCALE
\*------------------------------------*/

$font-base         : 1rem;
$line-height       : 1.4;

$font-size-sm      : 0.85rem;
$font-size-md      : $font-base;
$font-size-lg      : 1.3rem;


html {
  font-size: 18px;
  @media (min-width: $screen-sm-min) {
    font-size: 20px;
  }
  letter-spacing: .0625em;
  word-spacing: normal;
}


%font-size-sm {
  font-size: $font-size-sm;
  line-height: $line-height;
}
%font-size-md {
  font-size: $font-size-md;
  line-height: $line-height;
}
%font-size-lg {
  font-size: $font-size-lg;
  line-height: $line-height;
}



/*------------------------------------*\
  #SPACING
\*------------------------------------*/


$spacing-base     : 1rem;

$spacing-xxxl     : $spacing-base * 6;
$spacing-xxl      : $spacing-base * 4;
$spacing-xl       : $spacing-base * 3;
$spacing-lg       : $spacing-base * 2;
$spacing-md       : $spacing-base * 1.5;
$spacing-sm       : $spacing-base;
$spacing-xs       : $spacing-base / 2;
$spacing-xxs      : $spacing-base / 4;



/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/

$form-height  : 2rem;
