/*------------------------------------*\
  #MODULES
\*------------------------------------*/


@import "modules/_reset.scss";
@import "modules/_variables.scss";
@import "modules/_grid.scss";
@import "modules/_mixins.scss";
@import "modules/_typography.scss";
@import "modules/_global.scss";



/*------------------------------------*\
  #PARTIALS
\*------------------------------------*/

// components
@import "partials/components/_header.scss";
@import "partials/components/_widget-cart.scss";
@import "partials/components/_widget-search.scss";
@import "partials/components/_index-header.scss";
@import "partials/components/_index-product.scss";
@import "partials/components/_message.scss";
@import "partials/components/_tracklist.scss";
@import "partials/components/_detail-add-to-cart.scss";
@import "partials/components/_detail-description.scss";
@import "partials/components/_detail-images.scss";
@import "partials/components/_related-products.scss";
@import "partials/components/_cart-table.scss";
@import "partials/components/_cart-totals.scss";
@import "partials/components/_billing-fields.scss";
@import "partials/components/_order-review.scss";
@import "partials/components/_checkout-payment.scss";
@import "partials/components/_release.scss";
@import "partials/components/_info-form.scss";
@import "partials/components/_variations.scss";
// templates
@import "partials/templates/_container.scss";
@import "partials/templates/_index.scss";
@import "partials/templates/_detail.scss";
@import "partials/templates/_cart.scss";
@import "partials/templates/_checkout.scss";
@import "partials/templates/_thank-you.scss";


/*------------------------------------*\
  #VENDOR
\*------------------------------------*/

// @import "vendor/***.scss";



/*------------------------------------*\
  #BASE-STYLES
\*------------------------------------*/


body {
  @extend %font-body;
  background: $body-color;
  color: $base-color;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ::-moz-selection { background-color: rgba($base-color, .2); color: inherit; }
  ::selection { background-color: rgba($base-color, .2); color: inherit; }
}



/*------------------------------------*\
  #PRINT
\*------------------------------------*/


@media print {
  body {
    font-size: percentage(10 / 16);
  }
}
