/*------------------------------------*\
    #THANK-YOU
\*------------------------------------*/


.woocommerce-order-received {
  h2,
  h3 {
    @extend %font-size-md;
    @extend %font-body;
    margin-bottom: $spacing-sm;
  }
  h2 {
    display: inline-block;
    border-bottom: $border-width-sm solid $base-color;
  }
}

.woocommerce-thankyou-order-received {
  @extend %font-size-lg;
  margin-top: $spacing-xl;
  margin-bottom: $spacing-xl*2;
  text-align: center;
}

.wc-bacs-bank-details-heading {
  @extend %font-size-md;
  @extend %font-body;
  margin-top: $spacing-lg;
  margin-bottom: $spacing-md;
}

