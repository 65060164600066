/*------------------------------------*\
    #DETAIL
\*------------------------------------*/

.woocommerce-breadcrumb,
.product_meta {
  display: none;
}

.product-detail {
  border-top: $border-width-sm solid $base-color;
  padding-top: $spacing-xl;
}