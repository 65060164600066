/*------------------------------------*\
    #INDEX-PRODUCT
\*------------------------------------*/

.products .product {
  .woocommerce-LoopProduct-link {
    display: block;
  }
}

.product .attachment-shop_catalog {
  margin-bottom: $spacing-xxs !important;
}

.woocommerce ul.products li.product h3 {
  @extend %font-size-md;
  @extend %font-body;
}

.product .price {
  @extend %font-size-sm;
  @extend %font-var;
  color: $action-response !important;
}

.woocommerce ul.products li.product .button {
  border: $border-width-sm solid $base-color;
  background-color: $body-color;
  border-radius: 0;
  @extend %font-size-sm;
  @extend %font-var;
  color: $base-color;
  padding: $spacing-xxs;
  &:hover {
    border-color: $action-response;
    color: $action-response;
  }
}

a.added_to_cart {
  opacity: 1;
  @extend %font-var;
  @extend %font-size-sm;
  border-bottom: $border-width-sm $base-color solid;
  margin-left: $spacing-xs;
}

a.button.added {
  &:after {
    font-size: 0;
    width: 10px;
    height: 25px;
    display: inline-block;
    margin-left: $spacing-xs !important;
    margin-right: $spacing-xs/2 !important;
    background: url('../assets/images/check-white.svg') no-repeat center center;
  }
}

body:not(.touch).woocommerce ul.products li.product:hover {
  img {
    opacity: .5;
  }
}
body:not(.touch).woocommerce ul.products li.product:not(:hover) {
  .button:not(.added) {
    opacity: 0;
  }
}
