/*------------------------------------*\
    #WIDGET-CART
\*------------------------------------*/

.widget_shopping_cart {
  display: block !important; // toujours visible
  position: relative;
  z-index: 999;
  @media (min-width: $screen-sm-min) {
    &:not(:hover) .widget_shopping_cart_content {
      display: none;
    }
  }
  @media (max-width: $screen-xs-max) {
    .widget_shopping_cart_content {
      display: none !important;
    }
  }
}

.widget_shopping_cart_content {
  position: absolute;
  right: 0;
  width: 350px;
  color: $body-color;
  background-color: $base-color;
  border: $border-width-sm solid $body-color;
}

.widget_shopping_cart .widgettitle,
.cart-mobile a {
  color: $action-response;
  @extend %font-body;
  @extend %font-size-md;
  cursor: pointer;
}

.cart_list {
  margin-top: $spacing-sm !important;
}

.mini_cart_item {
  padding: 0 $spacing-sm 0 $spacing-lg !important;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: $border-width-sm;
    background-color: $body-color;
    margin: $spacing-xs 0;
  }
}

.cart_list li.empty {
  padding-bottom: $spacing-sm !important;
}

.mini_cart_item a.remove {
  font-size: 0 !important;
  width: 22px;
  height: 17px;
  left: $spacing-xs !important;
  background: url('../assets/images/cross-black.svg') no-repeat center center;
  &:hover {
    background: url('../assets/images/cross-red.svg') no-repeat center center;
  }
}

.mini_cart_item a:not(.remove) {
  color: $body-color;
  font-weight: normal !important;
  @extend %font-var;
  @extend %font-size-sm;
  margin-top: -.3em;
  overflow: auto !important; // hack clearfix pour l'image
  img {
    width: 20% !important;
    margin-left: $spacing-xs !important;
    margin-top: .3em;
  }
}

.mini_cart_item .quantity {
  display: block;
  @extend %font-body;
  @extend %font-size-sm;
}

.widget_shopping_cart_content .total,
.widget_shopping_cart_content .buttons {
  padding-left: $spacing-sm !important;
  padding-right: $spacing-sm !important;
}

.widget_shopping_cart_content .total {
  margin-top: $spacing-sm;
  border-top: none !important;
  padding-top: 0 !important;
  @extend %font-size-sm;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: $border-width-sm;
    background-color: $body-color;
    margin: $spacing-xs 0;
  }
}

.widget_shopping_cart_content .buttons {
  margin: $spacing-sm 0;
}

.widget_shopping_cart_content .buttons a {
  display: block;
  width: 100%;
  border: $border-width-sm solid $body-color;
  border-radius: 0;
  margin-bottom: $spacing-xxs;
  color: $base-color;
  @extend %font-body;
  @extend %font-size-sm;
  text-align: center;
  background-color: $body-color;
  &:not(.checkout) {
    background-color: $grey;  
  }
  &:hover {
    color: $action-response;
    border-color: $action-response;
    background-color: $body-color;
  }
}