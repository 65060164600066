/*------------------------------------*\
    #INDEX-HEADER
\*------------------------------------*/


.index-header {
  @media (min-width: $screen-sm-min) {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  margin-top: -$border-width-sm;
  margin-bottom: $spacing-md*2;
}

.index__title {
  @media (max-width: $screen-xs-max) {
    font-size: 150%;
    margin-top: $spacing-xs;
    margin-bottom: $spacing-xs;
  }
}

.index-header .nav-list {
  @media (min-width: $screen-sm-min) {
    display: flex;
    align-items: baseline;
  }
  @extend %font-var;
  @extend %font-size-sm;
  .list-item.chrono {
    margin-right: $spacing-sm;
  }
  .list-item a:hover {
    text-decoration: none;
  }
  .widget_product_search {
    @media (min-width: $screen-sm-min) {
      margin-left: $spacing-xl                                 ;
    }
  }
}

.index .nav-list li {
  @media (max-width: $screen-xs-max) {
    display: inline-block;
    &.widget {
      margin-top: $spacing-md;
      width: 100%;
    }
  }
}

input[type="search"] {
  @media (max-width: $screen-xs-max) {
    width: 100%;  
  }
}

body.alphabetic .list-item.chrono a {
  text-decoration: line-through;
}

body:not(.alphabetic) .list-item.alpha a {
  text-decoration: line-through;
}