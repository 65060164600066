/*------------------------------------*\
    #DETAIL-ADD-TO-CART
\*------------------------------------*/


.woocommerce div.product p.stock {
  @extend %font-var;
  @extend %font-size-sm;
  color: $base-color !important;
  margin-bottom: $spacing-xs;
  text-align: right;
}
.product-detail .quantity {
  display: none;
}

.woocommerce .product-detail .button.single_add_to_cart_button {
  width: 100%;
  color: $base-color;
  background-color: $body-color;
  border: $border-width-sm $base-color solid;
  border-radius: 0;
  @extend %font-body;
  @extend %font-size-md;
  &:hover {
    border-color: $action-response;
    color: $action-response;
    background-color: $body-color;
  }
  outline: none;
}