/*------------------------------------*\
    #RELATED-PRODUCTS
\*------------------------------------*/


.products.upsells {
  float: left;
  width: 100%;
  margin-top: $spacing-xl*2;
  border-top: $border-width-sm solid $base-color;
  padding-top: $spacing-xs;
}

.products.upsells h2 {
  @extend %font-body;
  @extend %font-size-sm;
  margin-bottom: $spacing-lg;
}

.woocommerce .products.upsells ul.products li.product {
  @media (max-width: $screen-xs-max) {
    width: 100%;
  }
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    width: calc(50% - 3px);
  }
  width: calc(25% - 6px);
}

.cross-sells {
  border-top: $border-width-sm solid $base-color;
  padding-top: $spacing-xs;
  h2 {
    @extend %font-body;
    @extend %font-size-sm;
    margin-bottom: $spacing-lg;
  }
}

.woocommerce .cart-collaterals .cross-sells ul.products li.product {
  width: calc(50% - 5px);
  @media (max-width: $screen-xs-max) {
    width: 100%;
  }
}